<template>
  <div class="main-wrapper">
    <Header/>
    <div class="d-flex flex-grow-1 justify-center align-center py-4" style="height: 100%;">
      <v-container>
        <v-row class="mb-8" justify="center" align="center">
          <div class="not-found-img">
            <img src="@/assets/images/404.svg" alt="Fraktus"/>
          </div>
        </v-row>
        <v-row class="mb-8" justify="center" align="center">
          <v-col cols="auto" class="text-center">
            <h1 class="text-h4">{{ '404_title'|localize}}</h1>
          </v-col>
        </v-row>
        <v-row class="mb-8" justify="center" align="center">
          <v-col cols="auto" class="text-center">
            <h6 class="text-subtitle-1 mb-2">{{ '404_text'|localize}}</h6>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="auto" class="py-0">
            <v-btn href="/" color="primary" depressed rounded>
              {{ 'home'|localize}}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <Footer/>
  </div>
</template>

<script>
  import Header from '@/components/common/Header';
  import Footer from '@/components/common/Footer';

  export default {
    name: "NotFound",
    components: {Header, Footer},
  };
</script>

<style lang="scss" scoped>
  .not-found-img {
    display: block;
    width: 130px;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
</style>
